<template>
  <div ref="model" class="container">
    <div @click="clickChat">
      <img class="img" :src="fn" v-if="fn?.indexOf('.png') > -1">    
      <video class="videoBg" autoplay="true" loop="true" muted="true" playsinline="true" onloadstart="" v-else>
        <source :src="fn">
      </video>
    </div>
    <div class="click-wrapper" v-if="clicked">
      <div class="close" @click="handleClose"></div>
      <div class="content">
        <h1 class="title space">{{ $t('lp.popup.title') }}</h1>
        <div class="email-field">
          <TextField
          :errorMsg="errorMsg"
            type="text"
            class="space purple"
            textColor="white"
            @change="handleInputChange"
            @input="handleInputChange"
            :value="userInfo.username"
            :isLoading="isLoading"
            :isValid="$v.userInfo.username.isValid"
            :dirty="$v.userInfo.username.$dirty"
            :placeholder="$t('registration.emailPage.usernametext')"
          />
        </div>
        <div class="highlight">
          <label class="orange">
            <input type="checkbox" @change="handleAccept" checked/>
            <span class="checkmark"></span>
            <span class="checklabel">{{ $t('registration.emailPage.checkbox') }}</span>
          </label>
        </div>

        <button class="btn button-wrapper" 
                type="button" 
                @click="clickChat" 
                :disabled="!userInfo.termsAccepted || !this.$v.userInfo.username.isValid || btnClick"
        >
          <Loader class="icon-button" v-if="btnClick"/>{{$t('toast.btn')}}
        </button>
      </div>
    </div>      
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { Namespaces } from '@/utils/constants/store.js';
import axios from 'axios';
import Footer from '@/components/Footer/Footer';
import { validationMixin } from 'vuelidate';

const {
  mapActions: registrationActions
} = createNamespacedHelpers(Namespaces.Registration);
const {
  mapActions: configActions,
  mapGetters: configGetters
} = createNamespacedHelpers(Namespaces.Config);

export default {
  name: 'index',
  components: {
    Footer,
  },
  mixins: [validationMixin],
  layout: 'noHeader',
  data () {
    return {
      models: {},
      category: 'UK_LP_Snap',
      modelId: null,
      swiperOptions: {
        autoHeight: true, //enable auto height
        effect: 'cards',
        // spaceBetween: 20,
        slidesPerView: 'auto',
        initialSlide: this.$storage.getState('lastseenmodel') || 2,
        centeredSlides: true
      },
      clicked: false,
      btnClick: false,
      userInfo: {
        email: '',
        username: '',
        termsAccepted: true
      },
      flowData: {
        gender: 'male',
        looking_gender: 'female',
        city: null,
        country: 'Germany',
        min_age: null,
        max_age: null,
        email: null,
        category_alias: this.$auth.$storage.getCookie('category') ?? 'ukrainian_girls'
      },
      isLoading: false,
      errorMsg: null,
      fn: null,
    };
  },
  validations: {
    userInfo: {
      username: {
        async isValid (value) {
          const isUsernameValid = value.match(/^([a-zA-Z0-9_-]){4,20}$/);
          if (!isUsernameValid) {
            this.errorMsg = this.$i18n.t('profile.username');
            return false;
          }
          this.errorMsg = this.$i18n.t('registration.emailPage.errormsg');
          this.isLoading = true;
          const UsernameVerified = await this.verifyUsername(this.userInfo.username);
          this.isLoading = false;
          return UsernameVerified;

          return true;
        }
      }
    }
  },
  async fetch () {
    // const position = this.$route.path.lastIndexOf("/")
    // const modelname = this.$route.path.slice(position + 1)
    // if (!modelname) return;
    // console.log('_id', this.$route.params.id, isNaN(Number(this.$route.params.id)))
    if (this.$route.params.id && isNaN(this.$route.params.id)) {
      return this.$nuxt.error({ statusCode: 404, message: 'Not Found' });
    }
    const { data, error } = await axios.get(`${this.$config.apiHost}/api/sanctum/getmodel/${this.$route.params.id}`)
      .catch(e => {
        return this.$nuxt.error({ statusCode: 404, message: 'Not found' });
      });
    // data.data.length = 4
    this.models = { ...data.data };
    // this.models = data.data.map(m => ({ ...m, distance: Math.floor(Math.random() * 25) + 1 }))
    this.fn = this.models.files.filter(f => f.path.indexOf('.mp4') !== -1)[0]?.path ?? this.models?.files[0].path;
    this.flowData.category_alias = this.models.categoryName;
    // console.log('Models', this.fn)
  },
  computed: {
    ...configGetters(['geoDataCity', 'geoDataCountry', 'geoDataIp', 'geoDataLat', 'geoDataLng']),
    generatePhoneNumber () {
      const zeroPad = (num, places) => String(num).padStart(places, '0');
      return ((process.env.NODE_ENV !== 'production') ? '4078999' : '3809999') + zeroPad(Math.floor(Math.random() * 10000), 5);
    },
    distance: () => {
      return Math.floor(Math.random() * 30) + 1;
    },
  },
  async beforeMount () {
    await this.getGeodata();
    const locale = 'en'; // this.$i18n.loadedLanguages[0] ?? 'en'
    // console.log(this.$i18n.loadedLanguages)
    this.flowData.country = this.geoDataCountry[locale];
    this.flowData.city = this.geoDataCity;
    this.flowData.ip_address = this.geoDataIp;
    // console.log('query', this.$route.query)
    const cid = this.$route.query.e1;
    if (cid) {
      this.$storage.setCookie('_cid', cid,
        {
          expires: new Date(Date.now() + 3 * 864e5)
        });
    }
    const afid = this.$route.query.e2;
    if (afid) {
      this.$storage.setCookie('_afid', afid,
        {
          expires: new Date(Date.now() + 3 * 864e5)
        });
    }
  },
  mounted () {
    // const h = ['localhost', 'app-f.flirtypoint.com']
    // if (!(this.$route.query.category || this.$auth.$storage.getCookie('category')) || !(this.$route.query.e1 || this.$route.query.e2) && h.indexOf(window.location.hostname) == -1 ) {
    // // if (!(this.$route.query.category || this.$auth.$storage.getCookie('category')) && h.indexOf(window.location.hostname) == -1 ) {
    //   window.location.href = 'https://duckduckgo.com'
    //   return
    // }
    this.userInfo.username = 'guest-' + Math.random().toString(16).substr(2, 6);
    // this.setHeightSlide();
    // setInterval(()=>{
    //   const sec = new Date().getSeconds();
    //   if (sec % 2 == 0) document.querySelector('.img').style = "object-fit: cover";
    //   else if (sec % 3 == 0) document.querySelector('.img').style = "object-fit: scale-down";
    //   else document.querySelector('.img').style = "object-fit: fill";
    // }, 1000)
    // if (typeof window !== "undefined") { window.history.replaceState(null, '', this.$route.path) }
  },
  methods: {
    ...configActions(['getGeodata']),
    ...registrationActions(['registerUser']),
    ...registrationActions(['checkUsername']),

    handleSend () {
      // window.location.href = "https://track.flirtypoint.com/click"
      window.location.href = ((process.env.NODE_ENV !== 'production') ? window.location.origin + '/rm7?category=ukrainian_girls' : 'https://track.flirtypoint.com/click');
      // let q = {category: 'ukrainian_girls'}
      // const cid = this.$storage.getCookie('_cid')
      // if (cid)
      //   q = {...q, e1: cid}
      // const afid = this.$storage.getCookie('_afid')
      // if (afid)
      //   q = {...q, e2: afid}
      // this.$router.push({ path: '/registration', query: q, replace: true })
      // this.$nextTick(() => {this.$router.replace({ name: "registration", query: q })})
      // let q = '/registration?category=ukrainian_girls'
      // const cid = this.$storage.getCookie('_cid')
      // if (cid)
      //   q += `&e1=${cid}`
      // const afid = this.$storage.getCookie('_afid')
      // if (afid)
      //   q += `&e2=${afid}`
      // // console.log(q, this.cid)
      // window.history.pushState( null, '', window.location.origin + q )
      // window.location.href = window.location.origin + q
      // this.clicked = false
    },
    setHeightSlide () {
      this.$refs.model.style.setProperty('--vh', window.innerHeight/100 + 'px');
    },
    async verifyUsername (name) {
      const res = await this.checkUsername( name );
      return res==='OK';
    },
    clickHandle (e) {
      // console.log(e.path[3].dataset.id, this.adult_ua[e.path[3].dataset.id])
      // this.mid = this.adult_ua[e.path[3].dataset.id]
      this.clicked = !this.clicked;
      this.showModal = !this.showModal;
    },
    clickChat () {    
      // if (this.clickedChat) {
      //   this.clickHandle()
      //   return
      // }
      const token =  this.$auth.$storage.getCookie('USER_ACCESS_TOKEN');
      if (token) {
        if (this.$route.query.index && this.$route.query.index === 'yes')
          this.$router.replace('/');
        else
          this.$router.replace(`/girls/${this.models.id}`);
        return;
      }
      this.btnClick = true;
      const _data = {
        'gender': 'male',
        'looking_gender': 'female',
        'min_age': 18,
        'max_age': 50,
        'username': this.userInfo.username,
        'termsAccepted': true,
      };
      const data = { ...this.flowData, ..._data };
      data.phone = this.generatePhoneNumber;
      data.email = `${data.username.toLowerCase()}@${this.$store.state.fakeDomain}`;
      setTimeout(()=> {
        this.btnClick = false;
      }, 5000);
      // console.log('reg', data)
      // return
      this.registerUser(data)
      .then(async response => {
        this.$auth.$storage.setCookie('category', data.category_alias);
        // window.location = window.location.origin + '/?chatid=' + this.selectedModel.id
        // console.log(response)
        const cid = this.$storage.getCookie('_cid');
        const afid = this.$storage.getCookie('_afid');
        if (cid) {
          // console.log('postback', { clickId: cid, affId: afid, txId: data.phone, event: 'registration' })
          await this.$api.post('v1/postback', { clickId: cid, affId: afid, txId: data.phone, event: 'registration' });
        }
        this.$gtm.push({ event: 'sign_up', 
          phone: data.phone, 
          country: data.country, 
          birthday: data.birthday, 
          date: new Date().toUTCString(),
          email: data.email, 
          username: data.username });
        if (this.$route.query.index && this.$route.query.index === 'yes')
          this.$router.replace('/');
        else
          this.$router.replace(`/girls/${this.models.id}`);
        // let q = null
        // if (this.mid) {
        //   q = { mid: this.mid }
        // }
        // this.$router.replace({ path: "/", query: q })
      })
      .catch(error => {
        // console.log(error)
        this.$toast.error(this.$i18n.t('registration.emailPage.adderror'), {
          position: 'bottom-center',
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: 'button',
          icon: true,
          rtl: false
        });
      });
    },
    handleAccept (value) {
      this.userInfo.termsAccepted = value;
      // this.$emit("termsAccepted", this.userInfo.termsAccepted)
    },
    handleInputChange (value) {
      this.userInfo.username = (value || '').trim();
      this.$v.userInfo.username.$touch();
    },
    handleClose () {
      this.clicked = false;
    }
  }
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$black: #000000;
$gray0: #f1f1f1;
$gray: #a2a2ab;
$gray2: #8b8b97;
$gray2sec: #8b8a96;
$gray3: #333248;
$gray4: #1c1b33;
$gray5: #04031e;
$gray6: #eeeeee;
$gray7: #8e8d99;
$lightgray: #888888;
$lightgray2: #555555;
$darkgray: #333333;
$darkgray2: #444444;
$darkblue: #17172f;
$darkblue2: #16152d;
$darkblue3: #16152c;
$darkblue4: #302a41;
$darkblue5: #03021d;
$purple: #8c3cd2;
$purple2: #3d335c;
$purple3: #2d224e;
$purple4: #aa66e5;
$lightpurple: #d77ff7;
$lightpurple2: #6323d3;
$lightpurple3: #6424d4;
$ultralightpurple: #c077ff;
$green: #00ce73;
$red: #ff0000;
$pink: #ff9dd1;
$darkorange: #eb881d;
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
.container {
  // min-height: calc(100 * var(--vh));
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  position: relative;
  background-position: center;
  img {
    width: 100%;
    border-radius: 0.688rem;
    height: 100%;
    object-fit: cover;
    background-color: darkgrey;
  }
}
.click-wrapper {
  z-index: 2;
  display: flex;
  // justify-content: center;
  border-radius: 5px;
  padding: 52px 16px 24px;
  position: fixed;
  background-color: $purple2;
  border: 1px solid $gray0;
  color: $gray0;
  left: 0;
  width: 100%;
  bottom: 30%;
  text-align: left;
  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    img {
      position: absolute;
      bottom:calc(100% + 19px);
      left: 50%;
      transform: translateX(-50%);
    }
    .info {
      // text-align: left;
      margin-top: 0;
      font-size: 1.125rem;
      padding-bottom: 5%;
    }
    .email-field {
      max-width: 100%;
    }
    .title {
      font-size: 24px;
    }
    .space {
      margin: 10px 0;
    }
  }
  .close {
    display: block;
    // position: relative;
    top: 0.2rem;
    right: 0.2rem;
    position: absolute;
    // right: 32px;
    // top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: $gray0;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}
.highlight {
  margin: 20px 0;
}
.orange {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // background-color: rgba($color: orange, $alpha: 0.1);
}
/* Hide the browser's default checkbox */
.orange input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background: transparent;
  border: 1px solid $purple4;
}
/* When the checkbox is checked, add a blue background */
.orange input:checked ~ .checkmark {
  background: linear-gradient(90deg, $lightpurple3 -2.93%, $lightpurple 105.33%);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.orange input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.orange .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $gray0;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.orange .checklabel {
  font-size: 18px;
  // margin-top: 20px;
  // padding: 20px 2px 20px 2px;
  color: $gray0;
}

.btn {
  padding: 5px 20px 4px;
  display: inline-block;
  color: $white;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  // margin-left: auto;
  // margin-right: auto;
}
.button-wrapper {
  font-size: 18px;
  padding: 11px 20px 10px;
  border: 0px;
  color: white;
  box-shadow: 0 0 20px rgba(207, 73, 216, 0.25);
  -webkit-box-shadow: 0 0 20px rgba(207, 73, 216, 0.25);
  -moz-box-shadow: 0 0 20px rgba(207, 73, 216, 0.25);
  background: $purple;
  background: linear-gradient(to right, #8c48fd 0%, #fd48bd 100%);
  background: -webkit-linear-gradient(to right, #8c48fd 0%, #fd48bd 100%);
  background: -moz-linear-gradient(to right, #8c48fd 0%, #fd48bd 100%);
  min-width: 300px;
}
video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-height: 100vh;
    transform: translate(-50%,-50%)
}
// video.videoBg {
//     min-width: 100vw;
//     // -webkit-filter: blur(5px);
//     // -ms-filter: blur(5px);
//     // -moz-filter: blur(5px)
// }
// @media only screen and (max-width: 767px) {
//     video.videoElement {
//         min-width:100vw
//     }
// }
</style>
