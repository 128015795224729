var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "model", staticClass: "container" }, [
    _c("div", { on: { click: _vm.clickChat } }, [
      _vm.fn?.indexOf(".png") > -1
        ? _c("img", { staticClass: "img", attrs: { src: _vm.fn } })
        : _c(
            "video",
            {
              staticClass: "videoBg",
              attrs: {
                autoplay: "true",
                loop: "true",
                muted: "true",
                playsinline: "true",
                onloadstart: "",
              },
              domProps: { muted: true },
            },
            [_c("source", { attrs: { src: _vm.fn } })]
          ),
    ]),
    _vm._v(" "),
    _vm.clicked
      ? _c("div", { staticClass: "click-wrapper" }, [
          _c("div", { staticClass: "close", on: { click: _vm.handleClose } }),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("h1", { staticClass: "title space" }, [
              _vm._v(_vm._s(_vm.$t("lp.popup.title"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "email-field" },
              [
                _c("TextField", {
                  staticClass: "space purple",
                  attrs: {
                    errorMsg: _vm.errorMsg,
                    type: "text",
                    textColor: "white",
                    value: _vm.userInfo.username,
                    isLoading: _vm.isLoading,
                    isValid: _vm.$v.userInfo.username.isValid,
                    dirty: _vm.$v.userInfo.username.$dirty,
                    placeholder: _vm.$t("registration.emailPage.usernametext"),
                  },
                  on: {
                    change: _vm.handleInputChange,
                    input: _vm.handleInputChange,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "highlight" }, [
              _c("label", { staticClass: "orange" }, [
                _c("input", {
                  attrs: { type: "checkbox", checked: "" },
                  on: { change: _vm.handleAccept },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
                _vm._v(" "),
                _c("span", { staticClass: "checklabel" }, [
                  _vm._v(_vm._s(_vm.$t("registration.emailPage.checkbox"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn button-wrapper",
                attrs: {
                  type: "button",
                  disabled:
                    !_vm.userInfo.termsAccepted ||
                    !this.$v.userInfo.username.isValid ||
                    _vm.btnClick,
                },
                on: { click: _vm.clickChat },
              },
              [
                _vm.btnClick
                  ? _c("Loader", { staticClass: "icon-button" })
                  : _vm._e(),
                _vm._v(_vm._s(_vm.$t("toast.btn")) + "\n      "),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }